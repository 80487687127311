.footer_wrapper{
    margin-top: 30px;
    padding: 30px;
    background-color: rgba(255, 255, 255, .2);

    display: flex;
    justify-content: center;

    .footer{
        display: flex;
        justify-content: space-around;
        width: 300px;

        a{
            background-color: rgba(200, 200, 200, .8);
            padding: 10px;
            border-radius: 10px;
        }

        .pop_up_footer{
            position: absolute;
            top: -100px;
            right: -90px;
            padding: 6px;
          
            color: rgb(255, 255, 255);
            background-color: rgba(109, 236, 119, 0.7);
            border-radius: 11px;
          
            font-size: 0.9em;
            text-align: left;
          
            transform: translatey(0px);
            -webkit-animation: float 5s ease-in-out infinite;
            animation: float 5s ease-in-out infinite;
          }
          
          .pop_up_footer::after{
              content: ".";
              position: absolute;
              display: block;
              bottom: -20px;
              left: 0;
          
              width: 55px;
              height: 11px;
          
              font-size: 55px;
              line-height: 30px;
              text-align: left;
          
              border-radius: 11px;
              background-color: rgba(109, 236, 119, 0.7);
          
              transform: translatey(0px);
              -webkit-animation: float2 5s ease-in-out infinite;
              animation: float2 5s ease-in-out infinite;
              font-weight: bold;
              -webkit-text-fill-color: rgba(109, 236, 119, 0.7);
                
              z-index: -2;
          }
        button{
            background-color: rgba(200, 200, 200, .8);
            padding: 10px;
            border: none;
            border-radius: 10px;

            &:hover{
                cursor: pointer;
            }
        }
    }
}