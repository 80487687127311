.chat-page{
    background-image: url(../imgs/bg_2.png);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  
    font-family: 'Dosis';

    h3{
        margin: 60px;
        font-size: 2em;
        text-align: center;
        color: white;
    }
    p{
        color: white;
    }
    .chat-page-p{
        text-align: center;
        color: white;
    }

    .password-box{
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0);
        z-index: 10;

        display: flex;
        justify-content: center;
        align-items: center;

        input{
            padding: 5px;
        }
    }

    .chat-gpt-component{
        padding-top: 90px;
        display: flex;
        flex-direction: column;

        align-items: center;

        .chatbox{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 30px;

            position: relative;

            .alert-popup{
                position: absolute;
                top: -22px;
                background: rgba(255, 122, 92, 0.9);
                padding: 12px;

                button{
                    border: none;
                    border-radius: 5px;
                    margin-top: 10px;
                }
            }

            .chat-input-holder{
               form{
                input{
                    border: none;
                    border-radius: 5px;
                    min-width: 280px;
                    min-height: 25px;
                    padding: 0 5px;
                }
               }

            }

            .chat-log{
                margin-top: 30px;
                padding: 20px;
                background: rgba(255, 179, 92, 0.5);
                border-radius: 5px;
                width: 80vw;
                max-height: 500px;

                overflow-y: scroll;
                
                .avatar{
                    font-weight: 600;
                }
                .message{
                    text-align: left;
                    margin-bottom: 20px;
                    padding: 5px;
                    border-radius: 10px;
                    background: rgba(92, 144, 255, 0.3)

                }
            }
        }
    }

}