// Fonts
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap');

// Partials
@import "Header.scss";
@import "Chakras_Model.scss";
@import "Bg_animation.scss";
@import "Footer.scss";
@import "ChatGPT.scss";
@import "Myths.scss";
@import "SentenceMover.scss";

* {
  margin: 0;
}

body{
  // cursor: url(../imgs/hand-cursor.png),auto;
}

h1 {
  font-weight: 300;
  letter-spacing: 5px;
}
h2, h3{
  font-weight: 300;
  letter-spacing: 3px;
}
p{
  font-size: 1.2em;
}
a{
  text-decoration: none;
  color: black;
}

$spacer-s: 15px;
$spacer-m: 30px;
$spacer-l: 60px;
$spacer-xl: 90px;

.home{
  background-image: url(../imgs/bg_2.png);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  font-family: 'Dosis';

}

.hero{
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  color: black;

  h1{
    font-size: 3em;
    font-weight: 300;
    letter-spacing: 5px;
    margin-bottom: $spacer-m;
    color: white;
  }
  

  .hero_banner{
    width: 100%;

    h2{
      font-weight: 300;
      letter-spacing: 5px;
      margin-bottom: $spacer-m;
      color: white;
    }
    p{
      font-size: 1.5em;
      color: white;
    }
  
    img{
      width: 150px;
      margin-bottom: $spacer-l;
    }
    // background-image: url('../imgs/hero_bg.jpg');
  }

  .call_to_action{
    margin: 0 30px 10px 30px;
    font-size: 1.5em;
    font-family: 'Dosis';
    padding: 6px 16px;

    border: none;
    border-radius: 10px;

    background-color: rgb(146, 220, 220);

    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

    transition: all ease-in-out 200ms;

    &:hover{
      transform: scale(1.05);
      cursor: pointer;
      // cursor: url(../imgs/hand-cursor-hover.png),auto;
    }
  }

  .description, .about_me{
    display: flex;
    flex-direction: column;

    width: 80%;
    padding: 5% 10%;
    text-align: left;
    background: linear-gradient(180deg, rgba(221, 220, 220, 0) 0%, rgba(221, 220, 220, 0.8) 22%, rgba(221, 220, 220, 0.8) 87%, rgba(16, 16, 16, 0.7) 100%) ;

    p{
      margin: $spacer-s
    };
  }
  .about_me{
    color: white;
    background: linear-gradient(180deg, rgba(16, 16, 16, 0.7) 5%, rgba(16, 16, 16, 0.8) 22%, rgba(16, 16, 16, 0.8) 90%, rgba(16, 16, 16, 1) 100%) ;
  }

  .hand_img{
    width: 300px;
    margin: auto auto 80px auto;
  }

  .meditating_img{
    width: 40vw;
    margin: auto;
    
    transition: all ease-in-out 500ms;
    animation: floating 2.5s alternate infinite ease-in;
  }
}

@keyframes floating {
  0%   {transform: scale(.97);}
  100% {transform: scale(1.01);}
}

.button_wrapper{
  position: relative;
}
.pop_up{
  position: absolute;
  top: -100px;
  right: -330px;
  padding: 6px;

  color: rgb(255, 255, 255);
  background-color: rgba(109, 236, 119, 0.7);
  border-radius: 11px;

  font-size: 0.9em;
  text-align: left;

  transform: translatey(0px);
  -webkit-animation: float 5s ease-in-out infinite;
  animation: float 5s ease-in-out infinite;
}

.pop_up::after{
    content: ".";
    position: absolute;
    display: block;
    bottom: -20px;
    left: 0;

    width: 55px;
    height: 11px;

    font-size: 55px;
    line-height: 30px;
    text-align: left;

    border-radius: 11px;
    background-color: rgba(109, 236, 119, 0.7);

    transform: translatey(0px);
    -webkit-animation: float2 5s ease-in-out infinite;
    animation: float2 5s ease-in-out infinite;
    font-weight: bold;
    -webkit-text-fill-color: rgba(109, 236, 119, 0.7);
      
    z-index: -2;
}

.testimonials_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 60px;

    .testimonios-title{
      color: white;
      font-weight: 300;
      letter-spacing: 3px;
      margin: 0 0 50px 0;
    }
  
    .testimonials{
      width: 100%;
      height: auto;
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

    h3{
      text-align: center;
      color: white;
      font-size: 2em;
    }

    .testimonial_name{
            margin-top: 33px;
          }

    .testimonials_content{
      display: flex;

      

      @media (max-width: 1026px){
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .testimonial{
        background-color: rgba($color: #ffffff, $alpha: 0.5);
        margin: 10px;
        padding: 10px;
        border-radius: 10px;

        .testimonial_name{
          text-align: right;
          font-weight: bold;
        }
      }
    }
  }
}

.testimonial {
  width: 250px;
  height: fit-content;
  text-align: center;
  background-color: rgba(255, 252, 252, 0.5);
  border-radius: 10px;
  padding: 10px;
  margin: 0 30px;

  p{
    font-size: 1.1em;
  }
}

.buttons_slider{
    .arrow {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 2rem;
    padding: 10px;
    z-index: 1;
  }

  .arrow-left {
    left: 10px;
  }

  .arrow-right {
    right: 10px;
  }
}


// animation for popup
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float2 {
  0% {
    line-height: 30px;
    transform: translatey(0px);
  }
  55% {
    transform: translatey(-10px);
  }
  60% {
    line-height: 10px;
  }
  100% {
    line-height: 30px;
    transform: translatey(0px);
  }
}
