// Charkas interactive
.chakras_page{
    background-image: url(../imgs/bg_2.png);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  
    font-family: 'Dosis';
  }
  
  .mobile_cover{
    position: absolute;
  
    display: none;
    background-color: white;
  
    font-size: 3em;
    text-align: center;
  
    width: 100vw;
    height: 100vh;
  
    @media (max-width: 1026px){
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .chakras_text_wrapper{
    display: flex;
    justify-content: center;

    .chakras_text{
        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 70%;
        
        h2{
            margin-bottom: 30px;
            text-align: center;
            color: white;
            font-size: 2.2em;
            opacity: 0;
            animation: fadeIn 2.5s 4s forwards;
        }
        
        p{
            text-align: left;
            color: white;
        
            opacity: 0;
            animation: fadeIn 2.5s 4s forwards;
        }
    }
  }
  
  .human_lotus{
    display: flex;
    justify-content: center;
    width: 100%;
  
    margin-top: -180px;
  
    position: relative;
  
    animation: lotus_position 2.5s 3s  forwards;
  
    @keyframes lotus_position {
      0%   {margin-top: -180px;}
      100% {margin-top: 20%;}
    }
  
    @media (max-width: 1026px){
      display: none;
    }
  
    .chakras_lotus{
      margin-bottom: 300px;
      width: 600px;
      opacity: 0.9;
      background-image: radial-gradient(rgb(255, 255, 255), rgba(255,0,0,0));
      border-radius: 50%;
    }
  
    .chakras_symbols{
      display: flex;
      flex-direction: column-reverse;
      margin: 28px auto;
      padding: 0;
  
      position: absolute;
      width: fit-content;  
      
  
      button{
        border: none;
        background-color: transparent;
      }
  
      img{
        width: 42px;
        transition: all ease-in-out 300ms;
  
        cursor: pointer;
      }
  
      img:hover{
        transform: scale(1.2);
        cursor: url(../imgs/hand-cursor.png),auto;
      }
  
      .ch{
        position: relative;
        margin-top: 24px;
        border-radius: 100px;
      color: black;

  
        button{
          animation: floating2 2.5s alternate infinite ease-in;
  
        }
  
      }
  
      @keyframes floating2 {
        0%   {transform: scale(.96);}
        100% {transform: scale(1.2);}
      }
  
      .ch1{
        background-image: radial-gradient(rgb(213, 53, 53)10%, rgba(255,0,0,0));
        opacity: 0;
        animation: fadeIn 1s 300ms forwards;
  
        ul{
          position: absolute;
          left:-500px;
          bottom: 0px;
        }
      }
      .ch2{
        background-image: radial-gradient(rgb(213, 122, 53)10%, rgba(255,0,0,0));
  
        opacity: 0;
        animation: fadeIn 1s 600ms forwards;
        
        ul{
          position: absolute;
          right:0px;
          bottom: 0px;
        }
      }
      .ch3{
        background-image: radial-gradient(rgb(236, 222, 62)10%, rgba(255,0,0,0));
  
        opacity: 0;
        animation: fadeIn 1s 900ms forwards;
  
        ul{
          position: absolute;
          left:-500px;
          top: -140px;
        }
      }
      .ch4{
        background-image: radial-gradient(rgb(146, 236, 62)10%, rgba(255,0,0,0));
  
        opacity: 0;
        animation: fadeIn 1s 1200ms forwards;
  
        ul{
          position: absolute;
          right:0;
          top: -120px;
        }
      }
      .ch5{
        background-image: radial-gradient(rgb(62, 137, 236)10%, rgba(255,0,0,0));
  
        opacity: 0;
        animation: fadeIn 1s 1500ms forwards;
  
        ul{
          position: absolute;
          left:-500px;
          top: -260px;
        }
      }
      .ch6{
        background-image: radial-gradient(rgb(103, 62, 236)10%, rgba(255,0,0,0));
  
        opacity: 0;
        animation: fadeIn 1s 1800ms forwards;
  
        ul{
          position: absolute;
          right:0;
          top: -240px;
        }
      }
      .ch7{
        background-image: radial-gradient(rgb(192, 62, 236)10%, rgba(255,0,0,0));
  
        opacity: 0;
        animation: fadeIn 1s 2100ms forwards;
  
        ul{
          position: absolute;
          left:-110px;
          top: -290px;
        }
      }
    }
  
  
  // Chakras dropdown
    .dropdown{
      display: none;
      position: absolute;
  
      width: 270px;
      height: fit-content;
      padding: 0;
  
      left: 270px;
      top: 0px;
  
      box-shadow: 0 10px 15px -3px rgba(253, 253, 253, 0.08),
        0 4px 6px -2px rgba(71, 63, 79, 0.16);
      z-index: 9999;
      list-style: none;
      border-radius: 20px;
  
      animation: fadeOut 1s;

      .submenu-items{
        width: 100%;
        height: 100%;
        padding: 10px;
      }
    }
    
    .dropdown.show{
      display: flex;
      // background-color: rgba(0, 0, 0, 0.7);
      color: black;
      animation: fadeIn 1s;
    }
  
    // FadeIn and Out animation for chakra signs
    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }
  
    @keyframes fadeOut {
      0% { opacity: 1; }
      100% { opacity: 0; }
    }
  
    li{
      padding: 10px 40px;
    }
  
    .chakra_color{
      display: flex;
      align-items: center;
  
      .color{
        width: 30px;
        height: 30px;
        border-radius: 50%;
  
        margin-left: 30px;
      }
    }
    
  }