.sentences-wrapper{
      background-image: url(../imgs/bg_2.png);
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      font-family: 'Dosis';
      
    .sentence-container {
      display: flex;
      flex-wrap: wrap;
      width: 100vw;
      padding: 60px;
      box-sizing: border-box;

      .sentence {
          margin: 30px;
          padding: 10px;
          font-size: 0.9em;
          
          width: auto;
          text-align: center;
          align-self: center;
          justify-self: center;
          animation: float 6s ease-in-out infinite;

          background-color: rgba(255, 255, 255, 0.7);
          border-radius: 10px;

          &:hover {
            transform: scale(1.2);
            transition: color 0.3s ease-in-out;
            cursor: pointer;
          }

          .author{
            font-size: 0.9em;
            color: #333;

          }
        }
  }
}


@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
