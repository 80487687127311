.header{
    display: flex;
    margin-bottom: 10px;
    padding: 20px;

    align-items: top;
    justify-content: space-between;
    color: white;

    nav{
        display: flex;

        @media (max-width:800px){
            flex-direction: column;
        }
    }


    a{
        text-decoration: none;
        color: white;
        width: fit-content;
        transition: all ease-in-out 400ms;
        padding: 3px 7px 3px 3px;
        border-radius: 3px;

        &:hover{
            cursor: pointer;
            // cursor: url(../imgs/hand-cursor-hover.png),auto;
        } 
    }

    a:hover{
        background-color: teal;
    }

    .location{
        display: flex;
        align-items: center;

        @media (max-width: 700px){
            font-size: 0.7em;
        }
    }
}